<script context="module" lang="ts">
	export declare namespace PaddingNamespace {
		type Props = {
			fullWidth?: boolean;
			fullHeight?: boolean;
			block?: boolean;
			all?: number;
			x?: number;
			y?: number;
			top?: number;
			bottom?: number;
			left?: number;
			right?: number;
			children: Snippet;
		};
	}
</script>

<script lang="ts">
	import { styles } from '$lib/utilities/core/styles';

	import classNames from 'classnames';
	import _ from 'lodash';
	import type { Snippet } from 'svelte';

	const {
		fullWidth = false,
		fullHeight = false,
		block = false,
		all,
		x,
		y,
		top,
		bottom,
		left,
		right,
		children,
	}: PaddingNamespace.Props = $props();

	const paddingBottomCondition = $derived(_.isNumber(bottom ?? y ?? all));
	const paddingTopCondition = $derived(_.isNumber(top ?? y ?? all));
	const paddingLeftCondition = $derived(_.isNumber(left ?? x ?? all));
	const paddingRightCondition = $derived(_.isNumber(right ?? x ?? all));

	const classes = $derived(
		classNames('Padding', {
			'Padding--fullWidth': fullWidth,
			'Padding--fullHeight': fullHeight,
			'Padding--block': block,
			'Padding--top': paddingTopCondition,
			'Padding--bottom': paddingBottomCondition,
			'Padding--left': paddingLeftCondition,
			'Padding--right': paddingRightCondition,
		}),
	);

	const styling = $derived(
		styles({
			'--Padding-top': top ?? y ?? all,
			'--Padding-bottom': bottom ?? y ?? all,
			'--Padding-right': right ?? x ?? all,
			'--Padding-left': left ?? x ?? all,
		}),
	);
</script>

<div class={classes} style={styling}>
	{@render children()}
</div>

<style lang="scss">
	.Padding {
		$self: &;

		&:not(&--block) {
			display: inline-flex;
		}

		&--block {
			display: block;
		}

		&--fullWidth {
			width: 100%;
		}

		&--fullHeight {
			height: 100%;
		}

		&--top {
			padding-top: calc(var(--padding) * var(--Padding-top));
		}

		&--bottom {
			padding-bottom: calc(var(--padding) * var(--Padding-bottom));
		}

		&--left {
			padding-left: calc(var(--padding) * var(--Padding-left));
		}

		&--right {
			padding-right: calc(var(--padding) * var(--Padding-right));
		}
	}
</style>
